import { Box, Button, Typography } from '@mui/material';
import { useWebSocket } from '../hooks/useWebSocket';
import { CLOSE_WEB_SOCKET, WEB_SOCKET_URL } from '../constants/web_socket';
import { useCallback, useEffect, useState } from 'react';
import { KitchenAPI } from '../services/kitchen_api';
import { CustomAlert, SeverityList } from './CustomAlert';
import {
  FLEX_COLUM_CENTER,
  FLEX_ROW,
  FLEX_ROW_CENTER,
} from '../constants/shared.styles';

export const DishControlPanel = () => {
  const [list, setList] = useState<any>([]);
  const { messages, isConnected, closeConnection } =
    useWebSocket(WEB_SOCKET_URL);

  const [showAlert, setShowAlert] = useState({
    message: '',
    severity: SeverityList.SUCCESS,
  });

  const handleListChange = useCallback(
    (message: any[]) => {
      if (message.length && isConnected) {
        setList(message);
      } else {
        if (list.length) {
          setList([]);
        }
      }
    },
    [list, isConnected],
  );

  const orderDishes = async (count: number) => {
    try {
      await KitchenAPI.registerManyDishes(count);
      /** @todo agregar respuesta al mensaje */
      setShowAlert({
        message: 'Pedido Realizado',
        severity: SeverityList.SUCCESS,
      });
    } catch (error) {
      setShowAlert({
        message: 'Error al realizar el pedido',
        severity: SeverityList.ERROR,
      });
    }
  };

  useEffect(() => {
    handleListChange(messages);
  }, [messages.length, isConnected]);

  useEffect(() => {
    if (CLOSE_WEB_SOCKET) {
      return () => closeConnection();
    }
  }, []);

  useEffect(() => {
    if (showAlert.message) {
      setTimeout(() => {
        setShowAlert({ ...showAlert, message: '' });
      }, 3000);
    }
  }, [showAlert]);

  const mapElements = (listToShow: string[]) => {
    return listToShow.map((dishOrder: any, index) => (
      <Box
        key={`${index}-dish-order-${dishOrder.id}`}
        sx={{
          padding: 2,
          border: '1px solid #32a8a6',
          borderRadius: 2,
        }}
      >
        <Typography>{`Pedido número: ${dishOrder.id}`}</Typography>
        <Typography>{`Receta: ${dishOrder.recipe.name}`}</Typography>
        <Typography>{`Estado: ${dishOrder.status}`}</Typography>
      </Box>
    ));
  };

  const renderListElements = (listElements: any[]) => {
    if (!listElements.length) return <></>;

    if (listElements.length > 10) {
      const notShownElementsCount = listElements.length - 10;
      const elementsToShow = listElements.slice(0, 9);

      return (
        <>
          <Box
            sx={{
              ...FLEX_COLUM_CENTER,
            }}
          >
            {mapElements(elementsToShow)}
          </Box>
          <Box>
            <Typography>{`${notShownElementsCount} Pedidos no mostrados`}</Typography>
          </Box>
        </>
      );
    }

    return mapElements(listElements);
  };

  return (
    <Box sx={FLEX_COLUM_CENTER}>
      {showAlert.message ? (
        <>
          <CustomAlert
            message={showAlert.message}
            severity={showAlert.severity}
          />
        </>
      ) : (
        <></>
      )}
      <Typography variant={'h3'}>Panel de Control Pedidos</Typography>

      <Box
        sx={{
          ...FLEX_COLUM_CENTER,
          // maxWidth: '40vw',
        }}
      >
        <Box
          sx={{
            ...FLEX_ROW_CENTER,
            justifyContent: 'space-around',
            width: '40vw',
            margin: 7,
          }}
        >
          <Button variant="contained" onClick={() => orderDishes(1)}>
            <Typography color={'white'} sx={{ fontWeight: 'bolder' }}>
              Pedir 1
            </Typography>
          </Button>
          <Button variant="contained" onClick={() => orderDishes(100)}>
            <Typography color={'white'} sx={{ fontWeight: 'bolder' }}>
              Pedir 100
            </Typography>
          </Button>
          <Button variant="contained" onClick={() => orderDishes(1000)}>
            <Typography color={'white'} sx={{ fontWeight: 'bolder' }}>
              Pedir 1000
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{
            ...FLEX_COLUM_CENTER,
            maxWidth: '35vw',
          }}
        >
          <Box
            sx={{
              margin: '0 0 20px 0',
            }}
          >
            <Typography variant={'h5'}>Pedidos Pendientes</Typography>
          </Box>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Typography
              variant={'h6'}
            >{`Cantidad de pedidos pendientes: ${list.length}`}</Typography>
          </Box>
          <Box
            sx={{
              padding: 2,
              border: '2px solid gray',
              borderRadius: 2,
              width: '35vw',
              minHeight: '10vw',
              margin: '10px 0 0 0',
            }}
          >
            {renderListElements(list)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
