import { Box, CircularProgress, Typography } from '@mui/material';

export const CustomPlaceHolder = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={10} />
        <Typography>Cargando...</Typography>
      </Box>
    </Box>
  );
};
