import axios from 'axios';
import { BuyHistoryItemDTO, IngredientDTO } from '../interfaces';
import { config } from '../config';

/** @todo doc */
export class WarehouseAPI {
  static readonly baseUrl =
    config.warehouseApi ?? 'http://localhost:3501/warehouse-api';

  static async listBuyOrders(order: string[], page: number, limit: number) {
    try {
      const results = await axios.get<{ items: BuyHistoryItemDTO[] }>(
        `${this.baseUrl}/buy-history/list`,
        {
          params: {
            order,
            page,
            limit,
          },
        },
      );
      return results.data.items;
    } catch (error) {
      console.error(`WarehouseAPI | listBuyOrders`, error);
      throw error;
    }
  }

  static async listIngredients() {
    try {
      const results = await axios.get<{ items: IngredientDTO[] }>(
        `${this.baseUrl}/ingredient/list`,
      );
      return results.data.items;
    } catch (error) {
      console.error(`WarehouseAPI | listIngredients`, error);
      throw error;
    }
  }
}
