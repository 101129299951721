import { useCallback, useEffect, useState } from 'react';
import { CustomAlert, SeverityList } from './CustomAlert';
import { DishOrderDTO } from '../interfaces';
import { KitchenAPI } from '../services/kitchen_api';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDateOrPreventError } from '../utils/formatToDateTime';
import { FLEX_COLUM_CENTER, FLEX_ROW_CENTER } from '../constants/shared.styles';

export const DishOrderList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dishOrderList, setDishOrdersList] = useState<DishOrderDTO[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [status, setStatus] = useState('FINISHED');
  const [listOrientation, setListOrientation] = useState('created_at');

  const [showAlert, setShowAlert] = useState({
    message: '',
    severity: SeverityList.SUCCESS,
  });

  useEffect(() => {
    if (showAlert.message) {
      setTimeout(() => {
        setShowAlert({ ...showAlert, message: '' });
      }, 3000);
    }
  }, [showAlert]);

  const handleGetOrders = useCallback(
    async (
      currentStatus: string,
      currentOrientation: string,
      currentPage: number,
      currentLimit: number,
    ) => {
      try {
        const results = await KitchenAPI.listDishOrders(
          [currentStatus],
          [currentOrientation],
          currentPage,
          currentLimit,
        );

        setDishOrdersList(results);
        setIsLoading(false);
        setShowAlert({
          message: 'Listado histórico encontrado',
          severity: SeverityList.SUCCESS,
        });
      } catch (error) {
        setShowAlert({
          message: (error as Error).message,
          severity: SeverityList.ERROR,
        });
      }
    },
    [],
  );

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  const handleLimitChange = (event: SelectChangeEvent) => {
    setLimit(Number(event.target.value));
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0) {
      setPage(newPage);
    }
  };

  const handlelistOrientation = () => {
    if (listOrientation === 'created_at') {
      setListOrientation('-created_at');
    } else {
      setListOrientation('created_at');
    }
  };

  useEffect(() => {
    handleGetOrders(status, listOrientation, page, limit);
  }, []);

  useEffect(() => {
    handleGetOrders(status, listOrientation, page, limit);
  }, [status, page, limit, listOrientation]);

  return (
    <Box>
      {showAlert.message ? (
        <>
          <CustomAlert
            message={showAlert.message}
            severity={showAlert.severity}
          />
        </>
      ) : (
        <></>
      )}
      <Box>
        <Box>
          <Box
            sx={{
              minWidth: '50vw',
              borderBottom: 1,
              borderColor: 'divider',
              margin: '0 0 10px 0',
              ...FLEX_COLUM_CENTER,
            }}
          >
            <Typography variant="h3">Histórico de Pedidos</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="status-select">Filtrar por estado</InputLabel>
              <Select
                labelId="status-select"
                value={status}
                defaultValue="FINISHED"
                autoWidth
                label="Filtrar por estado"
                onChange={(event) => handleStatusChange(event)}
              >
                <MenuItem value="FINISHED">
                  <Typography color={'green'}>Entregado</Typography>
                </MenuItem>
                <MenuItem value="QUEUED">
                  <Typography color={'orange'}>En Espera</Typography>
                </MenuItem>
                <MenuItem value="IN_PROGRESS">
                  <Typography color={'blue'}>En Proceso</Typography>
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="limit-select">Cantidad de Elementos</InputLabel>
              <Select
                labelId="limit-select"
                value={`${limit}`}
                defaultValue="10"
                autoWidth
                label="Cantidad de Elementos"
                onChange={(event) => handleLimitChange(event)}
              >
                <MenuItem value="10">
                  <Typography color={'black'}>10</Typography>
                </MenuItem>
                <MenuItem value="15">
                  <Typography color={'black'}>15</Typography>
                </MenuItem>
                <MenuItem value="20">
                  <Typography color={'blue'}>20</Typography>
                </MenuItem>
              </Select>
            </FormControl>

            <Box
              sx={{
                ...FLEX_ROW_CENTER,
                padding: 10,
              }}
            >
              <Box
                sx={{
                  margin: 2,
                }}
              >
                <Typography fontWeight={'bold'}>
                  {`página: ${page}`}{' '}
                </Typography>
              </Box>
              <Box
                sx={{
                  ...FLEX_ROW_CENTER,
                }}
              >
                <Button
                  onClick={() => {
                    handlePageChange(page - 1);
                  }}
                >
                  Anterior
                </Button>
                <Button
                  onClick={() => {
                    handlePageChange(page + 1);
                  }}
                >
                  Siguiente
                </Button>
              </Box>
            </Box>

            <Box>
              <Button
                onClick={() => {
                  handlelistOrientation();
                }}
              >
                Cambiar Orden Fecha
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <></>
      ) : (
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography fontWeight={'bold'}>Número de Orden</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={'bold'}>Receta</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={'bold'}>Estado</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={'bold'}>Fecha de Pedido</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={'bold'}>
                    Fecha de Actualización
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dishOrderList.map((dishOrder) => (
                <TableRow id={`order-row-${dishOrder.id}`}>
                  <TableCell align="center">
                    <Typography>{dishOrder.id}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{dishOrder.recipe.name}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{dishOrder.status}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      {formatDateOrPreventError(dishOrder.created_at)}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      {formatDateOrPreventError(dishOrder.updated_at)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};
