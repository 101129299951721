import { useCallback, useEffect, useState } from 'react';
import { CustomAlert, SeverityList } from './CustomAlert';
import { BuyHistoryItemDTO } from '../interfaces';
import { WarehouseAPI } from '../services/warehouse_api';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDateOrPreventError } from '../utils/formatToDateTime';
import { FLEX_COLUM_CENTER, FLEX_ROW_CENTER } from '../constants/shared.styles';

export const BuyList = () => {
  const [list, setList] = useState<BuyHistoryItemDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState({
    message: '',
    severity: SeverityList.SUCCESS,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [listOrientation, setListOrientation] = useState('created_at');

  const handleGetBuyList = useCallback(
    async (
      currentOrientation: string,
      currentPage: number,
      currentLimit: number,
    ) => {
      try {
        const results = await WarehouseAPI.listBuyOrders(
          [currentOrientation],
          currentPage,
          currentLimit,
        );

        setList(results);
        setIsLoading(false);
        setShowAlert({
          message: 'Listado histórico encontrado',
          severity: SeverityList.SUCCESS,
        });
      } catch (error) {
        setShowAlert({
          message: (error as Error).message,
          severity: SeverityList.ERROR,
        });
      }
    },
    [],
  );

  const handleLimitChange = (event: SelectChangeEvent) => {
    setLimit(Number(event.target.value));
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0) {
      setPage(newPage);
    }
  };

  const handlelistOrientation = () => {
    if (listOrientation === 'created_at') {
      setListOrientation('-created_at');
    } else {
      setListOrientation('created_at');
    }
  };

  useEffect(() => {
    if (showAlert.message) {
      setTimeout(() => {
        setShowAlert({ ...showAlert, message: '' });
      }, 3000);
    }
  }, [showAlert]);

  useEffect(() => {
    handleGetBuyList(listOrientation, page, limit);
  }, []);

  useEffect(() => {
    handleGetBuyList(listOrientation, page, limit);
  }, [listOrientation, page, limit]);

  return (
    <Box>
      {showAlert.message ? (
        <>
          <CustomAlert
            message={showAlert.message}
            severity={showAlert.severity}
          />
        </>
      ) : (
        <></>
      )}
      <Box>
        <Box>
          <Box
            sx={{
              minWidth: '50vw',
              borderBottom: 1,
              borderColor: 'divider',
              margin: '0 0 10px 0',
              ...FLEX_COLUM_CENTER,
            }}
          >
            <Typography variant="h3">Histórico de Compras</Typography>
          </Box>

          <Box
            sx={{
              ...FLEX_ROW_CENTER,
              justifyContent: 'space-around',
            }}
          >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="limit-select">Cantidad de Elementos</InputLabel>
              <Select
                labelId="limit-select"
                value={`${limit}`}
                defaultValue="10"
                autoWidth
                label="Cantidad de Elementos"
                onChange={(event) => handleLimitChange(event)}
              >
                <MenuItem value="10">
                  <Typography color={'black'}>10</Typography>
                </MenuItem>
                <MenuItem value="15">
                  <Typography color={'black'}>15</Typography>
                </MenuItem>
                <MenuItem value="20">
                  <Typography color={'black'}>20</Typography>
                </MenuItem>
              </Select>
            </FormControl>

            <Box
              sx={{
                ...FLEX_ROW_CENTER,
                padding: 10,
              }}
            >
              <Box
                sx={{
                  margin: 2,
                }}
              >
                <Typography fontWeight={'bold'}>{`página: ${page}`}</Typography>
              </Box>
              <Box
                sx={{
                  ...FLEX_ROW_CENTER,
                }}
              >
                <Button
                  onClick={() => {
                    handlePageChange(page - 1);
                  }}
                >
                  Anterior
                </Button>
                <Button
                  onClick={() => {
                    handlePageChange(page + 1);
                  }}
                >
                  Siguiente
                </Button>
              </Box>
            </Box>

            <Box>
              <Button
                onClick={() => {
                  handlelistOrientation();
                }}
              >
                Cambiar Orden Fecha
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <></>
      ) : (
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography fontWeight={'bold'}>Número de Compra</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={'bold'}>Pedido Vinculado</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={'bold'}>Ingrediente</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={'bold'}>Cantidad Comprada</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={'bold'}>Fecha de Compra</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((buyOrder) => (
                <TableRow id={`order-row-${buyOrder.id}`}>
                  <TableCell align="center">
                    <Typography>{buyOrder.id}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{buyOrder.dish_order_id}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{buyOrder.ingredient}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>{buyOrder.quantity_bought}</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      {formatDateOrPreventError(buyOrder.created_at)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};
