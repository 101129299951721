import { useCallback, useEffect, useState } from 'react';
import { RecipeDTO } from '../interfaces';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { KitchenAPI } from '../services/kitchen_api';
import { CustomAlert, SeverityList } from './CustomAlert';
import { CustomPlaceHolder } from './CustomPlaceHolder';
import {
  FLEX_COLUM_CENTER,
  FLEX_ROW,
  FLEX_ROW_CENTER,
} from '../constants/shared.styles';

export const RecipeList = () => {
  const [list, setList] = useState<RecipeDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState({
    message: '',
    severity: SeverityList.SUCCESS,
  });

  const handleGetRecipes = useCallback(async () => {
    try {
      const recipes = await KitchenAPI.getRecipes();
      setList(recipes);
      setIsLoading(false);
      setShowAlert({
        message: 'Recetas Obtenidas',
        severity: SeverityList.SUCCESS,
      });
    } catch (error) {
      setShowAlert({
        message: (error as Error).message,
        severity: SeverityList.ERROR,
      });
    }
  }, []);

  useEffect(() => {
    handleGetRecipes();
  }, []);

  useEffect(() => {
    if (showAlert.message) {
      setTimeout(() => {
        setShowAlert({ ...showAlert, message: '' });
      }, 3000);
    }
  }, [showAlert]);

  return (
    <Box sx={{ ...FLEX_COLUM_CENTER }}>
      {showAlert.message ? (
        <>
          <CustomAlert
            message={showAlert.message}
            severity={showAlert.severity}
          />
        </>
      ) : (
        <></>
      )}
      <Box
        sx={{
          minWidth: '50vw',
          borderBottom: 1,
          borderColor: 'divider',
          margin: '0 0 30px 0',
          ...FLEX_COLUM_CENTER,
        }}
      >
        <Typography variant="h3">Recetas</Typography>
      </Box>
      {!isLoading ? (
        <Box
          sx={{
            minWidth: '50vw',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{ fontWeight: 'bolder' }}
                  >
                    Nombre Receta
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{ fontWeight: 'bolder' }}
                    textAlign={'center'}
                  >
                    Ingredientes
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((receta, index) => {
                return (
                  <TableRow key={`${index}-recipe-${receta.name}`}>
                    <TableCell align="center">
                      <Typography color={'primary'} fontWeight={'bold'}>
                        {receta.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          ...FLEX_ROW_CENTER,
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                        }}
                      >
                        {receta.ingredients.map((ingredient, index) => {
                          return (
                            <Box
                              key={`${index}-ingredient-${ingredient}`}
                              sx={{
                                margin: 1,
                                padding: 2,
                                ...FLEX_ROW,
                              }}
                            >
                              <Typography
                                fontWeight={'bold'}
                              >{`${ingredient.name}:  `}</Typography>
                              <Typography sx={{ marginLeft: 2 }}>
                                {ingredient.quantity}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <CustomPlaceHolder />
      )}
    </Box>
  );
};
