import { useEffect, useRef, useState } from 'react';

export const useWebSocket = (url: string) => {
  const socket = useRef<WebSocket | null>(null);
  const [messages, setMessages] = useState<any[]>([]);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    if (!socket.current) {
      socket.current = new WebSocket(url);

      socket.current.onopen = () => {
        console.info('WebSocket | Connection established');
        setIsConnected(true);
      };

      socket.current.onmessage = (event) => {
        if (event.data instanceof Blob) {
          const reader = new FileReader();

          reader.onload = () => {
            const textData = reader.result as string;
            try {
              const parsedData = JSON.parse(textData);
              setMessages(parsedData);
            } catch (error) {
              console.error('WebSocket | Failed to parse message:', error);
              setMessages([]);
            }
          };

          reader.onerror = (error) => {
            console.error('WebSocket | Failed to read Blob:', error);
            setMessages([]);
          };

          reader.readAsText(event.data);
        } else {
          try {
            const parsedData = JSON.parse(event.data.toString());
            console.log('WebSocket | Message DATA:', parsedData);
            setMessages(parsedData);
          } catch (error) {
            console.error('WebSocket | Failed to parse message:', error);
            setMessages([]);
          }
        }
      };

      socket.current.onclose = () => {
        console.warn('WebSocket | Connection closed');
        setIsConnected(false);
      };

      socket.current.onerror = (error) => {
        console.error('WebSocket | Error occurred:', error);
      };
    }
  }, [url]);

  const closeConnection = () => {
    socket.current?.close();
  };

  return { messages, isConnected, closeConnection };
};
