export const FLEX = {
  display: 'flex',
};

export const FLEX_ROW = {
  ...FLEX,
  flexDirection: 'row',
};

export const FLEX_COLUMN = {
  ...FLEX,
  flexDirection: 'column',
};

export const ALIGN_CENTER = {
  alignItems: 'center',
};

export const FLEX_ROW_CENTER = {
  ...FLEX_ROW,
  ...ALIGN_CENTER,
};

export const FLEX_COLUM_CENTER = {
  ...FLEX_COLUMN,
  ...ALIGN_CENTER,
};
