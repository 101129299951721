import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#32a8a6',
    },
    secondary: {
      // main: "#ffffff",
      main: '#32a8a6',
    },
    text: {
      secondary: '',
    },
  },
  components: {},
});
