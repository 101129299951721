import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';

export enum SeverityList {
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface CustomAlertProps {
  message: string;
  severity: SeverityList;
}

export const CustomAlert = ({ message, severity }: CustomAlertProps) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 10,
        right: 10,
        width: '20vw',
      }}
    >
      <Alert severity={severity}>{message}</Alert>
    </Box>
  );
};
