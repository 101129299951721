export const formatDateOrPreventError = (fecha: string) => {
  try {
    const dateTime = new Date(fecha)
      .toISOString()
      .split('.')[0]
      .replace('T', ' ');
    return dateTime;
  } catch (error) {
    console.warn(
      `formatDateOrPreventError: Error: ${(error as Error).message}`,
    );
    return 'No Disponible';
  }
};
