const {
  NODE_ENV,
  REACT_APP_ENV,
  REACT_APP_KITCHEN_API_URL,
  REACT_APP_WAREHOUSE_API_URL,
  REACT_APP_WEB_SOCKET_URL,
} = process.env;

const getConfig = () => {
  return {
    nodeEnv: NODE_ENV ?? REACT_APP_ENV,
    kitchenApi: REACT_APP_KITCHEN_API_URL ?? 'http://185.137.92.38/kitchen-api',
    warehouseApi:
      REACT_APP_WAREHOUSE_API_URL ?? 'http://185.137.92.38/warehouse-api',
    webSocketHost: REACT_APP_WEB_SOCKET_URL ?? 'ws://185.137.92.38/web-socket',
  };
};

const config = getConfig();

export { config };
