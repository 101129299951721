import { Box, Button, Container, Typography } from '@mui/material';
import { useState } from 'react';
import { DishControlPanel } from './DishControlPanel';
import { RecipeList } from './RecipeList';
import { DishOrderList } from './DishOrderList';
import { BuyList } from './BuyList';
import { IngredientList } from './IngredientList';

const Landing = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabsList = [
    {
      name: 'Panel Pedidos',
      element: <DishControlPanel />,
    },
    {
      name: 'Recetas',
      element: <RecipeList />,
    },
    {
      name: 'Historial Pedidos',
      element: <DishOrderList />,
    },
    {
      name: 'Stock',
      element: <IngredientList />,
    },
    {
      name: 'Compras',
      element: <BuyList />,
    },
  ];

  const renderTabOptions = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        <Box>
          <Typography
            variant="h3"
            color={'primary'}
            sx={{ textDecoration: 'underline' }}
          >
            Alegra Challenge: Día de Beneficencia
          </Typography>
        </Box>
        <Box>
          {tabsList.map((tab, index) => (
            <Button
              key={`button-${tab.name}`}
              color={'secondary'}
              variant="text"
              sx={{
                margin: '1vw',
                borderBottom: selectedTab === index ? '2px solid #32a8a6' : '',
              }}
              onClick={() => {
                setSelectedTab(index);
              }}
            >
              {tab.name}
            </Button>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {renderTabOptions()}
      </Box>
      <Box
        sx={{
          padding: 5,
        }}
      >
        {tabsList[selectedTab].element}
      </Box>
    </Container>
  );
};

export { Landing };
