import axios from 'axios';
import { DishOrderDTO, RecipeDTO, RegisterDishDTO } from '../interfaces';
import { config } from '../config';

export class KitchenAPI {
  static readonly baseUrl =
    config.kitchenApi ?? 'http://localhost:3500/kitchen-api';

  static async registerDish(): Promise<RegisterDishDTO | null> {
    try {
      const result = await axios.post<{ data: RegisterDishDTO }>(
        `${this.baseUrl}/dish`,
      );
      return result.data.data;
    } catch (error) {
      console.error(`KitchenAPI | getDish`, error);
      throw error;
    }
  }

  static async registerManyDishes(dishCount: number) {
    const promisesList = [];
    for (let index = 0; index < dishCount; index++) {
      promisesList.push(this.registerDish());
    }
    const responses = await Promise.allSettled(promisesList);

    const results = responses.map((response, index) => {
      if (response.status === 'fulfilled') {
        return response.value;
      } else {
        throw new Error(`No se ha podido registrar la orden`);
      }
    });

    return results;
  }

  static async getRecipes() {
    try {
      const results = await axios.get<{ items: RecipeDTO[] }>(
        `${this.baseUrl}/recipe/list`,
      );
      return results.data.items;
    } catch (error) {
      console.error(`KitchenAPI | getRecipes`, error);
      throw error;
    }
  }

  static async listDishOrders(
    status: string[],
    order: string[],
    page: number,
    limit: number,
  ) {
    try {
      const results = await axios.get<{ items: DishOrderDTO[] }>(
        `${this.baseUrl}/dish/list`,
        {
          params: {
            status,
            order,
            page,
            limit,
          },
        },
      );
      return results.data.items;
    } catch (error) {
      console.error(`KitchenAPI | listDishOrders`, error);
      throw error;
    }
  }
}
