import { useCallback, useEffect, useState } from 'react';
import { IngredientDTO } from '../interfaces';
import { CustomAlert, SeverityList } from './CustomAlert';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { WarehouseAPI } from '../services/warehouse_api';
import { CustomPlaceHolder } from './CustomPlaceHolder';
import { formatDateOrPreventError } from '../utils/formatToDateTime';
import { FLEX_COLUM_CENTER } from '../constants/shared.styles';

export const IngredientList = () => {
  const [list, setList] = useState<IngredientDTO[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState({
    message: '',
    severity: SeverityList.SUCCESS,
  });

  const handleGetIngredients = useCallback(async () => {
    try {
      const ingredients = await WarehouseAPI.listIngredients();
      setList(ingredients);
      setIsLoading(false);
      setShowAlert({
        message: 'Ingredientes Obtenidos',
        severity: SeverityList.SUCCESS,
      });
    } catch (error) {
      setShowAlert({
        message: (error as Error).message,
        severity: SeverityList.ERROR,
      });
    }
  }, []);

  const handleUpdateList = async () => {
    setIsLoading(true);
    handleGetIngredients();
  };

  useEffect(() => {
    handleGetIngredients();
  }, [handleGetIngredients]);

  useEffect(() => {
    if (showAlert.message) {
      setTimeout(() => {
        setShowAlert({ ...showAlert, message: '' });
      }, 3000);
    }
  }, [showAlert]);

  return (
    <Box sx={FLEX_COLUM_CENTER}>
      {showAlert.message ? (
        <>
          <CustomAlert
            message={showAlert.message}
            severity={showAlert.severity}
          />
        </>
      ) : (
        <></>
      )}
      <Box
        sx={{
          minWidth: '50vw',
          borderBottom: 1,
          borderColor: 'divider',
          margin: '0 0 10px 0',
          ...FLEX_COLUM_CENTER,
        }}
      >
        <Typography variant="h3">Ingredientes</Typography>
      </Box>
      <Box
        sx={{
          ...FLEX_COLUM_CENTER,
          padding: 3,
        }}
      >
        <Button onClick={() => handleUpdateList()}>
          <Typography fontWeight={'bold'}>Actualizar Listado</Typography>
        </Button>
      </Box>
      {!isLoading ? (
        <Box
          sx={{
            minWidth: '50vw',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography fontWeight={'bold'}>Nombre</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={'bold'}>Cantidad Actual</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight={'bold'}>
                    Fecha Actualización Stock
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((ingredient, index) => {
                return (
                  <TableRow id={`${index}-ingredient-${ingredient}`}>
                    <TableCell align="center">{ingredient.name}</TableCell>
                    <TableCell align="center">{ingredient.quantity}</TableCell>
                    <TableCell align="center">
                      {formatDateOrPreventError(ingredient.updated_at!)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <CustomPlaceHolder />
      )}
    </Box>
  );
};
